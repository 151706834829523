import { useContext, useEffect, useState } from "react";
import apiaxios from "../../common/apiaxios";
import css from "./Dictionaries.module.css";
import userStatusContext from "../../context/userStatusContext";
import SelectVendor from "../ProductsList/SelectVendor";
import SelectCategory from "./SelectCaregory/SelectCategory";
import CheckBool from "../control/CheckBool/CheckBool";

const VendorsCategories = () => {
  const { selectVendor } = useContext(userStatusContext);
  const [categoriesList, setCategoriesList] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [fullShow, setFullShow] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedID, setSelectedID] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  useEffect(() => {
    apiaxios
      .get("/catalog/get_vendors_categories/", {
        params: {
          vendor: selectVendor,
          fullShow: fullShow,
        },
      })
      .then((res) => {
        setCategoriesList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectVendor, fullShow]);

  useEffect(() => {
    if (selectedCategory) {
      apiaxios
        .post(
          "/catalog/set_vendor_category/",
          selectedCategory,
          { headers: { "Content-Type": "application/json" } },
          { withCredentials: true }
        )
        .then(() => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedCategory]);

  const modalOpen = (e) => {
    setEditOpen(true);
    setTitle(
      "Обераємо відповідність для категорії: " +
        e.currentTarget.getAttribute("data-name")
    );
    setSelectedID(e.currentTarget.id);
  };

  const modalClose = () => {
    setEditOpen(false);
    setTitle("");
    setSelectedID("");
  };

  return (
    <div className={css["dictionary-container"]}>
      <div className={css["dictionary-header"]}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={css["dictionary-title"]}>
            Категорії товару постачальників
          </div>
          <div style={{ padding: "0px 10px" }}>{categoriesList.length}</div>
          <CheckBool
            value={fullShow}
            setValue={setFullShow}
            label={"показувати всі"}
          />
        </div>
        <div className={css["dictionary-header-btn-block"]}>
          <div className={css["dictionary-title-add-item"]}>
            <SelectVendor />
          </div>
        </div>
      </div>
      <div>
        <div className={css["dictionary-row-head"]}>
          <div className={css["dictionary-item"]} style={{ width: "10%" }}>
            Постачальник
          </div>
          <div className={css["dictionary-item"]} style={{ width: "10%" }}>
            Код
          </div>
          <div className={css["dictionary-item"]} style={{ width: "35%" }}>
            Назва
          </div>
          <div className={css["dictionary-item"]} style={{ width: "10%" }}>
            Код
          </div>
          <div className={css["dictionary-item"]} style={{ width: "35%" }}>
            Назва
          </div>
        </div>
        {categoriesList.map((item) => (
          <div
            key={item["id"]}
            id={item["id"]}
            data-name={item["vendor_category_name"]}
            className={css["dictionary-row"]}
            onClick={modalOpen}
          >
            <div className={css["dictionary-item"]} style={{ width: "10%" }}>
              {item["vendor"]}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "10%" }}>
              {item["vendor_category_id"]}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "35%" }}>
              {item["vendor_category_name"]}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "10%" }}>
              {item["categoryID"] ? item["categoryID"] : "-"}
            </div>
            <div className={css["dictionary-item"]} style={{ width: "35%" }}>
              {item["category_name"] ? item["category_name"] : "-"}
            </div>
          </div>
        ))}
      </div>
      {editOpen && (
        <SelectCategory
          title={title}
          selectedID={selectedID}
          setSelectedCategory={setSelectedCategory}
          modalClose={modalClose}
        />
      )}
    </div>
  );
};

export default VendorsCategories;

import { useEffect, useState } from "react";
import CustomInput from "../control/CustomInput/CustomInput";
import SelectCategory from "../Dictionaries/SelectCaregory/SelectCategory";
import apiaxios from "../../common/apiaxios";
import CustomTextArea from "../control/CustomTextArea/CustomTextArea";
import ButtonSave from "../control/ButtonSave/ButtonSave";

const AddProductCard = ({
  nameIn,
  articulIn,
  categoryIDIn,
  countryIn,
  briefDescriptionIn,
  codeBrainIn,
  warrantyIn,
  koduktvedIn,
  EANIn,
  imageIn,
  priceImIn,
  priceRecIn,
  // activeSaveDef=false,
}) => {
  const [categorySelectOpen, setCategorySelectOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const [name, setName] = useState(nameIn);
  const [articul, setArticul] = useState(articulIn);
  const [categoryID, setCategoryID] = useState(categoryIDIn);
  const [categoryName, setCategoryName] = useState(categoryIDIn);
  const [country, setCountry] = useState(countryIn);
  const [codeBrain, setCodeBrain] = useState(codeBrainIn);
  const [briefDescription, setBriefDescription] = useState(briefDescriptionIn);
  const [warranty, setWarranty] = useState(warrantyIn);
  const [koduktved, setKoduktved] = useState(koduktvedIn);
  const [EAN, setEAN] = useState(EANIn);
  const [image, setImage] = useState(imageIn);
  const [priceIm, setPriceIm] = useState(priceImIn);
  const [priceRec, setPriceRec] = useState(priceRecIn);
  // const [activeSave, setActiveSave] = useState(activeSaveDef);

  // useEffect(() => {
  //   setActiveSave(true)
  // }, [setName]);
  // }, [name, articul, codeBrain, categoryID, briefDescription, priceIm, priceRec, image, koduktved, EAN, warranty, country]);

  useEffect(() => {
    if (categoryID) {
      if (selectedCategory) {
        setCategoryID(selectedCategory["categoryID"]);
      }
      apiaxios
        .get("/catalog/category_path/" + categoryID)
        .then((res) => {
          let path = "";
          for (const item of res.data) {
            path = path + item["name"] + " / ";
          }
          setCategoryName(path);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedCategory, categoryID]);

  const modalOpen = () => {
    setCategorySelectOpen(true);
    setTitle("Обераємо категорію для: " + name);
  };

  const modalClose = () => {
    setCategorySelectOpen(false);
    setTitle("");
  };

  return (
    <div style={{ width: "600px", marginTop: "10px" }}>
      <CustomInput
        value={name}
        setValue={setName}
        label={"Назва товару:"}
        width={"420px"}
      />
      <CustomInput
        value={articul}
        setValue={setArticul}
        label={"Артикул товару:"}
        width={"420px"}
      />
      <CustomInput
        value={codeBrain}
        setValue={setCodeBrain}
        label={"Код по BRAIN:"}
        width={"420px"}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginLeft: "3px",
          marginTop: "10px",
        }}
        onClick={modalOpen}
      >
        <div>Категорія товару:</div>
        <div style={{ width: "420px", fontSize: "14px" }}>
          {categoryName ? categoryName : "Немає"}{" "}
        </div>
      </div>
      {categorySelectOpen && (
        <SelectCategory
          title={title}
          selectedID={categoryID}
          setSelectedCategory={setSelectedCategory}
          modalClose={modalClose}
        />
      )}
      <CustomTextArea
        value={briefDescription}
        setValue={setBriefDescription}
        label={"Опис:"}
        width={"592px"}
        rows={"5"}
      />
      <CustomInput
        value={priceIm}
        setValue={setPriceIm}
        label={"Ціна продажу:"}
        width={"420px"}
      />
      <CustomInput
        value={priceRec}
        setValue={setPriceRec}
        label={"Рекомендована:"}
        width={"420px"}
      />
      <CustomInput
        value={image}
        setValue={setImage}
        label={"Зображення:"}
        width={"420px"}
      />
      <CustomInput
        value={koduktved}
        setValue={setKoduktved}
        label={"Код УКТВЕД:"}
        width={"420px"}
      />
      <CustomInput
        value={EAN}
        setValue={setEAN}
        label={"Штрихкод:"}
        width={"420px"}
      />
      <CustomInput
        value={warranty}
        setValue={setWarranty}
        label={"Гарантія, міс:"}
        width={"420px"}
      />
      <CustomInput
        value={country}
        setValue={setCountry}
        label={"Країна:"}
        width={"420px"}
      />
      <ButtonSave
        data={{
          name: name,
          articul: articul,
          codeBrain: codeBrain,
          categoryID: categoryID,
          briefDescription: briefDescription,
          priceIm: priceIm,
          priceRec: priceRec,
          image: image,
          koduktved: koduktved,
          EAN: EAN,
          warranty: warranty,
          country: country,
        }}
        redirect="/portal/search_product_info"
        requestPath={"/catalog/add_product/"}
      />
    </div>
  );
};

export default AddProductCard;

import { useState } from "react";
import css from "./SearchProductInfo.module.css";
import { Circles } from "react-loader-spinner";
import apiaxios from "../../common/apiaxios";
import AddProductCard from "../ProductCard/AddProductCard";

const SearchProductInfo = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState("articul");
  const [info, setInfo] = useState("");
  const [circle, setCircle] = useState(false);

  const changeText = (e) => {
    setSearchText(e.target.value);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const submitSearch = () => {
    if (searchText) {
      setCircle(true);
      apiaxios
        .get("/catalog/get_product_info/", {
          params: {
            searchText: searchText.trim().replace(/\s+/g, " "),
            searchType: selectedOption,
          },
        })
        .then((res) => {
          setInfo(res.data);
          setCircle(false);
        })
        .catch((err) => {
          console.log(err);
        });
      setSearchText("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && searchText) {
      submitSearch();
    }
  };

  const addProductBrain = (searchText) => {
    setCircle(true);
    apiaxios
      .get("/catalog/add_product_by_code_brain/", {
        params: {
          searchText: searchText.trim().replace(/\s+/g, " "),
          searchType: selectedOption,
        },
      })
      .then(() => {
        apiaxios
          .get("/catalog/get_product_info/", {
            params: {
              searchText: searchText.trim().replace(/\s+/g, " "),
              searchType: selectedOption,
            },
          })
          .then((res) => {
            setInfo(res.data);
            setCircle(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={css["container"]}>
      <div>Пошук товару за</div>
      <div>
        <form>
          <label style={{ marginRight: "25px" }}>
            <input
              type="radio"
              name="searchType"
              value="articul"
              checked={selectedOption === "articul"} // Перевірка обраного значення
              onChange={handleChange} // Відстеження змін
            />
            <span style={{ paddingLeft: "5px" }}>артикулом</span>
          </label>
          <label>
            <input
              type="radio"
              name="searchType"
              value="brain_code"
              checked={selectedOption === "brain_code"} // Перевірка обраного значення
              onChange={handleChange} // Відстеження змін
            />
            <span style={{ paddingLeft: "5px" }}>кодом BRAIN</span>
          </label>
        </form>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={css["search"]}>
          <input
            type="text"
            placeholder="Пошук..."
            value={searchText}
            onChange={changeText}
            onKeyDown={handleKeyDown}
            className={css["search-input"]}
          />
          <button onClick={submitSearch} className={css["search-btn"]}>
            <div style={{ fill: "#ffffff" }}>
              <svg width="15" height="15">
                <use xlinkHref="#svg-icon__search" />
              </svg>
            </div>
          </button>
        </div>
      </div>
      {circle && (
        <div className="spinner">
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!circle && info && (
        <>
          <div style={{ marginTop: "10px" }}>
            Картка в базі:{" "}
            {info["base_products"].map((prod) => (
              <a
                href={"/product_details/" + prod["code"]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {prod["name"]}
              </a>
            ))}
            {info["base_products"].length === 0 && <>відсутня!</>}
          </div>
          {info["prods"].map((prod) => (
            <>
              {prod["vendor"] === "BRAIN" && (
                <>
                  <div style={{ marginTop: "10px" }}>
                    BRAIN: {prod["info"]["product_code"]} {prod["info"]["name"]}{" "}
                    {info["base_products"].length === 0 && (
                      <button
                        onClick={() => {
                          addProductBrain(
                            selectedOption === "articul"
                              ? prod["info"]["articul"]
                              : prod["info"]["product_code"]
                          );
                        }}
                      >
                        +
                      </button>
                    )}
                  </div>
                  <AddProductCard
                    nameIn={prod["info"]["name"]}
                    articulIn={prod["info"]["articul"]}
                    categoryIDIn={prod["info"]["categoryID"]}
                    countryIn={prod["info"]["country"]}
                    codeBrainIn={prod["info"]["product_code"]}
                    briefDescriptionIn={prod["info"]["brief_description"]}
                    warrantyIn={prod["info"]["warranty"]}
                    koduktvedIn={prod["info"]["koduktved"]}
                    EANIn={prod["info"]["EAN"]}
                    imageIn={prod["info"]["full_image"]}
                    priceImIn={prod["info"]["retail_price_uah"]}
                    priceRecIn={prod["info"]["recommendable_price"]}
                  />
                  {/* <pre>{JSON.stringify(info["info"], null, 2)}</pre> */}
                </>
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default SearchProductInfo;
